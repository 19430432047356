export const memberData = [
  {
    name: 'Allen',
    page: '/allen',
    og: 'allen.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/allen-chun-cpa-70732b15/',
      soundcloud: 'https://soundcloud.com/0shimmy/tracks',
    },
    description: 'CPA, Accounting Manager',
    headshot: 'allen.jpg',
    location: 'Fort Lee, NJ',
    experience: [
      {
        company: 'Ulysses Management',
        logo: 'ulysses.png',
        title: 'Accounting Manager',
        period: 'Aug 2017 – Present',
      },
      {
        company: `PKF O'Connor`,
        logo: 'pkf.jpg',
        title: 'Senior Audit Associate',
        period: 'Aug 2013 – Aug 2017',
      },
      {
        company: 'PwC',
        logo: 'pwc.jpg',
        title: 'Experienced Assurance Associate',
        period: 'Sep 2010 – Jun 2013',
      },
    ],
    education: [
      {
        school: 'Binghamton University',
        logo: 'binghamton.png',
        degree: 'B.S. Accounting',
        period: '2007',
      },
    ],
  },
  {
    name: 'Andrew',
    page: '/andrew',
    og: 'andrew.png',
    social: {
      site: 'http://andrew-lee.squarespace.com/',
      linkedin: 'https://www.linkedin.com/in/andrewxlee',
      vimeo: 'https://vimeo.com/user9322866',
    },
    description: 'Animator',
    headshot: 'andrew.jpg',
    location: 'Boston, MA',
    experience: [
      {
        company: 'MullenLowe U.S.',
        logo: 'mullen.jpg',
        title: 'Animator',
        period: 'Nov 2017 – Present',
      },
      {
        company: `Nimblebot`,
        logo: 'nimblebot.png',
        title: 'Lead Animator',
        period: 'Nov 2014 – Oct 2017',
      },
      {
        company: 'The Molecule',
        logo: 'molecule.png',
        title: 'Motion Graphic Intern',
        period: 'May 2014 – Aug 2014',
      },
    ],
    education: [
      {
        school: 'School of Visual Arts',
        logo: 'sva.jpg',
        degree: 'B.F.A. Computer Art',
        period: '2009 – 2014',
      },
      {
        school: 'Sungkyunkwan University',
        logo: 'skku.png',
        degree: 'B.F.A. Communication Design',
        period: '2003 – 2007',
      },
    ],
  },
  {
    name: 'B',
    page: '/b',
    og: 'b.png',
    social: {
      site: 'https://bald.design',
      github: 'https://github.com/baadaa',
      linkedin: 'https://www.linkedin.com/in/bumhan/',
      vimeo: 'https://vimeo.com/baadaa',
    },
    description: 'Design director who writes code',
    headshot: 'bald-head-square.png',
    location: 'New York, NY',
    experience: [
      {
        company: 'Homepoint',
        logo: 'homepoint.jpg',
        title: 'Design Director',
        period: 'Aug 2020 – Present',
      },
      {
        company: 'Updater',
        logo: 'updater.png',
        title: 'Brand Designer',
        period: 'Jan 2018 – Aug 2020',
      },
      {
        company: 'The Moderns',
        logo: 'tm.jpg',
        title: 'Designer/Developer',
        period: 'Oct 2013 – Oct 2016',
      },
      {
        company: 'Young & Rubicam Group',
        logo: 'yrg.png',
        title: 'Designer/Developer',
        period: 'Aug 2009 – Sep 2013',
      },
      {
        company: 'Independent consultant',
        logo: 'independent.png',
        title: 'Designer/Developer',
        period: 'Aug 2003 – Dec 2007',
      },
      {
        company: 'Republic of Korea Army',
        logo: 'roka.png',
        title: 'Sergeant',
        period: 'Jun 2005 – Jun 2007',
      },
    ],
    education: [
      {
        school: 'Pratt Institute',
        logo: 'pratt.jpg',
        degree: 'M.S. Communications Design',
        period: '2010 – 2013',
      },
      {
        school: 'Korea University',
        logo: 'ku.png',
        degree: 'M.A. dropout, Clinical & Counseling Psychology',
        period: '2003 – 2004',
      },
      {
        school: 'Korea University',
        logo: 'ku.png',
        degree: 'B.A. Linguistics & Psychology',
        period: '1999 – 2003',
      },
      {
        school: 'Daewon Foreign Language High School',
        logo: 'daewon.jpg',
        degree: 'Diploma, German Language & Culture',
        period: '1996 – 1998',
      },
    ],
  },
  {
    name: 'Ed',
    page: '/ed',
    og: 'ed.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/ed-suh-3284a517/',
    },
    description: 'CAMS, Compliance Manager',
    headshot: 'ed.png',
    location: 'New York, NY',
    experience: [
      {
        company: 'Shinhan Bank',
        logo: 'shinhan.jpg',
        title: 'Assistant Manager, Compliance',
        period: 'Jan 2020 – Present',
      },
      {
        company: 'Shinhan Bank',
        logo: 'shinhan.jpg',
        title: 'Compliance Analyst',
        period: 'Apr 2019 – Jan 2020',
      },
      {
        company: 'Self-employed',
        logo: 'unknown.png',
        title: 'Translator',
        period: 'Oct 2015 – Present',
      },
      {
        company: 'Edelman Arts',
        logo: 'edelman.png',
        title: 'Associate',
        period: 'Sep 2014 – Mar 2017',
      },
      {
        company: 'Artemus USA LLC',
        logo: 'artemus.png',
        title: 'Associate',
        period: 'Sep 2014 – Mar 2017',
      },
      {
        company: 'Artnet',
        logo: 'artnet.jpg',
        title: 'Gallery Sales Intern',
        period: 'Oct 2013 – Nov 2013',
      },
      {
        company: `Christie's`,
        logo: 'christie.jpg',
        title: 'Intern, Post-War & Contemporary Art',
        period: 'Jan 2013 – Jun 2013',
      },
      {
        company: `The Aperture Foundation`,
        logo: 'aperture.jpg',
        title: 'Finance and Administration Intern',
        period: 'Jan 2012 – Jun 2012',
      },
      {
        company: `Artist Pension Trust`,
        logo: 'artist-pension.png',
        title: 'Collections Management Intern',
        period: 'Oct 2011 – Feb 2012',
      },
      {
        company: `Culture Ocean`,
        logo: 'unknown.png',
        title: 'Foreign Correspondent',
        period: 'Jun 2010 – Oct 2011',
      },
    ],
    education: [
      {
        school: 'Teachers College of Columbia University',
        logo: 'tccu.jpg',
        degree: 'M.A. coursework completed for Arts Administration',
      },
      {
        school: 'SUNY Stony Brook',
        logo: 'stonybrook.jpg',
        degree: 'B.A. Art History & Economics',
      },
    ],
  },
  {
    name: 'Edward',
    page: '/edward',
    og: 'edward.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/hyoungtae-edward-kwon-1a448743/',
    },
    description: 'Resident Doctor, Anesthesiology',
    headshot: 'edward.png',
    location: 'New York, NY',
    experience: [
      {
        company: 'Maimonides Medical Center',
        logo: 'maimonides.png',
        title: 'Resident Doctor',
        period: 'Jul 2019 – Present',
      },
      {
        company: 'Whitehead Institute',
        logo: 'whitehead.jpg',
        title: 'Research Technician',
        period: 'Aug 2009 – Apr 2011',
      },
    ],
    education: [
      {
        school: 'Mount Sinai School of Medicine of New York University',
        logo: 'mount-sinai.png',
        degree: 'Doctor of Medicine (M.D.)',
        period: '2011 – 2016',
      },
      {
        school: 'Swarthmore College',
        logo: 'swarthmore.png',
        degree: 'B.A. Biochemistry & Psychology',
        period: '2004 – 2008',
      },
    ],
  },
  {
    name: 'Hal',
    page: '/hal',
    og: 'hal.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/hal-choi-21a95959/',
    },
    description: 'Architect',
    headshot: 'hal-.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: 'None',
        logo: 'unknown.png',
        title: 'None',
        period: '2012 – Present',
      },
    ],
    education: [
      {
        school: 'Pratt Institute',
        logo: 'pratt.jpg',
        degree: 'Bachelor of Architecture',
        period: '2011 – 2016',
      },
    ],
  },
  {
    name: 'Hoon',
    page: '/hoon',
    og: 'hoon.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/hoonrhee/',
      behance: 'https://www.behance.net/rhdble',
    },
    description: 'Digital / Visual Designer',
    headshot: 'hoon.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: 'R/GA',
        logo: 'rga.jpg',
        title: 'Designer',
        period: 'Jan 2015 – Present',
      },
      {
        company: `Time Warner Cable`,
        logo: 'twc.png',
        title: 'Visual Designer / Motion Graphics Animator',
        period: 'Sep 2014 – Nov 2014',
      },
      {
        company: 'R/GA',
        logo: 'rga.jpg',
        title: 'Junior Visual Designer',
        period: 'Dec 2013 – May 2014',
      },
      {
        company: 'Ad Lubow Advertising',
        logo: 'adlubow.png',
        title: 'Freelance Graphic Designer',
        period: 'Oct 2013 – Nov 2013',
      },
      {
        company: 'Lacoste',
        logo: 'lacoste.jpg',
        title: 'Freelance Graphic Designer',
        period: 'Jul 2013 – Aug 2013',
      },
      {
        company: `Time Warner Cable`,
        logo: 'twc.png',
        title: 'Freelance Graphic Designer',
        period: 'May 2013 – Jun 2013',
      },
      {
        company: `Time Warner Cable`,
        logo: 'twc.png',
        title: 'Motion Graphics / Graphic Design Intern',
        period: 'Jan 2013 – May 2013',
      },
      {
        company: `Hearst Magazines`,
        logo: 'hearst.jpg',
        title: 'Graphic Design Intern',
        period: 'Jan 2013 – May 2013',
      },
      {
        company: `Indika Entertainment Advertising`,
        logo: 'indika.png',
        title: 'Graphic Design Intern',
        period: 'Sep 2012 – Jan 2013',
      },
      {
        company: `Chalres Masters Studio`,
        logo: 'unknown.png',
        title: 'Assistant Photographer',
        period: 'Sep 2012 – Jan 2013',
      },
      {
        company: `Republic of Korea Army`,
        logo: 'roka.png',
        title: 'Sergeant',
        period: 'Jan 2003 – Feb 2005',
      },
    ],
    education: [
      {
        school: 'Pratt Institute',
        logo: 'pratt.jpg',
        degree: 'M.S. Design and Visual Communications, Digital Emphasis',
        period: '2011 – 2013',
      },
      {
        school: 'Yonsei University',
        logo: 'yonsei.png',
        degree: 'B.F.A. Design and Visual Communications',
        period: '2002 – 2009',
      },
    ],
  },
  {
    name: 'Kevin',
    page: '/kevin',
    og: 'kevin.png',
    social: {
      linkedin: 'https://www.linkedin.com/in/hongyeol-kevin-jeon-328043b4/',
      soundcloud: 'https://soundcloud.com/hongyeol-kevin-jeon/tracks',
    },
    description: 'R.N., Certified Dialysis Nurse',
    headshot: 'kevin.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: 'New York-Presbyterian Hospital',
        logo: 'nyp.png',
        title: 'Registered Nurse',
        period: 'Jul 2016 – Present',
      },
      {
        company: `The Brooklyn Hospital Center`,
        logo: 'brooklyn-hospital.png',
        title: 'Registered Nurse',
        period: 'Feb 2012 – Jul 2016',
      },
    ],
    education: [
      {
        school: 'Grand Canyon University',
        logo: 'gcu.jpg',
        degree: `Master's Degree in Nursing`,
        period: '2019 – 2021',
      },
      {
        school: 'SUNY Downstate Medical Center',
        logo: 'suny-downstate.png',
        degree: `Bachelor's Degree in Nursing`,
        period: '2014 – 2016',
      },
      {
        school: 'Queensborough Community College',
        logo: 'qcc.png',
        degree: `Associate's Degree in Nursing`,
        period: '2008 – 2010',
      },
    ],
  },
  {
    name: 'Paul',
    page: '/paul',
    og: 'paul.png',
    social: {
      github: 'https://github.com/paulleephp',
      linkedin: 'https://www.linkedin.com/in/paul-lee-2b445734/',
    },
    description: 'Full-Stack Developer',
    headshot: 'paul.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: 'Vimbly',
        logo: 'vimbly.png',
        title: 'Full-Stack Developer',
        period: 'Nov 2014 – Present',
      },
      {
        company: `Qlovi`,
        logo: 'qlovi.png',
        title: 'Web Application Developer',
        period: 'Sep 2013 – Nov 2014',
      },
      {
        company: 'USJH',
        logo: 'pwc.jpg',
        title: 'Softwaare Developer',
        period: 'Oct 2012 – Sep 2013',
      },
    ],
    education: [
      {
        school: 'Queens College',
        logo: 'queens-college.png',
        degree: 'B.S. Computer Science',
        period: '2004 – 2007',
      },
    ],
  },
  {
    name: 'Suho',
    page: '/suho',
    og: 'suho.png',
    social: {
      site: 'https://cargocollective.com/suhosong',
      linkedin: 'https://www.linkedin.com/in/suhosong/',
    },
    description: 'Senior Product Designer',
    headshot: 'suho.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: 'Thimble',
        logo: 'thimble.jpg',
        title: 'Senior Product Designer',
        period: 'Sep 2020 – Present',
      },
      {
        company: 'Thimble',
        logo: 'thimble.jpg',
        title: 'Visual Designer',
        period: 'Jan 2018 – Sep 2020',
      },
      {
        company: `Lookscope Inc`,
        logo: 'look.png',
        title: 'Head of Product Design',
        period: 'Sep 2013 – Nov 2014',
      },
      {
        company: 'Self-employed',
        logo: 'unknown.png',
        title: 'Freelance Graphic Designer',
        period: '2015 – Dec 2017',
      },
      {
        company: 'RAPP',
        logo: 'rapp.jpg',
        title: 'Graphic Designer',
        period: 'Nov 2014 – Jul 2015',
      },
      {
        company: 'Everything Studio',
        logo: 'everything.png',
        title: 'Graphic Designer',
        period: 'Jan 2014 – Jul 2014',
      },
      {
        company: `You've Got Pictures`,
        logo: 'ygp.png',
        title: 'Art Director',
        period: 'Jul 2012 – Apr 2013',
      },
      {
        company: `Njenworks`,
        logo: 'unknown.png',
        title: 'Graphic Designer',
        period: 'Aug 2011 – Dec 2011',
      },
      {
        company: `MTV Networks`,
        logo: 'unknown.png',
        title: 'Motion Graphic Designer',
        period: 'May 2011 – Aug 2011',
      },
    ],
    education: [
      {
        school: 'Parsons School of Design',
        logo: 'parsons.jpg',
        degree: 'B.F.A. Design and Visual Communications, General',
        period: '2010 – 2014',
      },
    ],
  },
  {
    name: 'Young',
    page: '/young',
    og: 'young.png',
    social: {
      site: 'http://www.youngjinlim.com/',
    },
    description: 'Art Director • Designer • Illustrator • DJ',
    headshot: 'young.jpg',
    location: 'New York, NY',
    experience: [
      {
        company: `St. Martin's Press`,
        logo: 'st-martins.png',
        title: 'Art Director',
        period: 'Jun 2008 – Present',
      },
    ],
    education: [
      {
        school: 'Temple University',
        logo: 'temple.png',
        degree: '',
        period: '',
      },
    ],
  },
];
